/* eslint-disable react/prop-types */
import React from 'react'
import { Icon } from '@iconify/react'

import { Flex } from '@components/Grid'
import { Text } from '@components/Text'



const ValueCard = ({ title, description, icon, fill }) => (
  <Flex
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    flexGrow="1"
    maxWidth={['100%', '30%', '30%', '30%']}
    bg="blue.500"
    px={3}
    py={5}
    m={2}
    position="relative"
  >
    <Icon icon={icon} height="140px" color={fill} />

    <Text
      position="relative"
      zIndex="2"
      textAlign="center"
      fontSize={4}
      as="h4"
      mb={3}
    >
      {title}
    </Text>
    <Text position="relative" zIndex="2" textAlign="center" variant="body">
      {description}
    </Text>
  </Flex>
)

export default ValueCard
