import React from 'react'

import { Layout } from '@components/Layout'
import { SEO } from '@components/SEO'
import MyTabs from '@components/Tab'
import { Program, Benefits } from '../screens/JoinUs'
import { Hero, HeroTitle, ProgramDes } from '../screens/JoinUsEN'

const JoinUsEs = () => {
  return (
    <Layout>
      <SEO title="Únete a nosotros" />
      <HeroTitle
        subtitle="Ofertas Laborales"
        titleProgram="Tenemos los mejores programas diseñados para ti."
        email="info@aibrok.com.mx"
        suggestion="Envíanos tu CV a "
      />
      <MyTabs
        labelTab2="Asesor"
        labelTab="Agente Exclusivo"
        tabContent={
          <>
            <Hero titleProgram="Asesor" joinUs="Únete a Nosotros" />
            <ProgramDes
              descriptionOne="¿Te gustaría trabajar en el sector, trabajando en una de las que está experimentando mayor crecimiento a nivel nacional e internacional?"
              subDescriptionOne="En Aibrok, empresa de referencia del sector, buscamos agentes colaboradores que aporten conocimientos, habilidades y experiencia en la gestión de clientes. Personas comprometidas, proactivas y con ganas de aprender y de aportar valor."
              descriptionTwo="La continua transformación del mundo asegurador está haciendo que la profesionalidad y la especialización sea la mejor manera de mejorar resultados y reducir costes. Esta optimización de recursos nos llevará a ser más solventes y estar en continuo crecimiento."
              subDescriptionTwo="  Si quieres desarrollar tu carrera profesional en el sector
                financiero tecnológico, esta es tu oportunidad."
            />
            <Benefits />
          </>
        }
        tabContent2={
          <>
            <Hero titleProgram="Asesor" joinUs="Únete a Nosotros" />
            <ProgramDes
              descriptionOne="¿Te gustaría trabajar en el sector, trabajando en una de las que está experimentando mayor crecimiento a nivel nacional e internacional?"
              subDescriptionOne="En Aibrok, empresa de referencia del sector, buscamos agentes colaboradores que aporten conocimientos, habilidades y experiencia en la gestión de clientes. Personas comprometidas, proactivas y con ganas de aprender y de aportar valor."
              descriptionTwo="   ¡En Aibrok te ofrecemos la oportunidad de construir una
              carrera exitosa, obteniendo una visión global del negocio con un
              programa de formación y desarrollo y un plan de carrera
              personalizado como Agente Financiero!"
            />
            <Program />
          </>
        }
      />
    </Layout>
  )
}

export default JoinUsEs
