/* eslint-disable react/prop-types */
import React from 'react'
import { Icon } from '@iconify/react'

import { Flex } from '@components/Grid'
import { Text } from '@components/Text'



const ValueCard = ({  description, icon, fill }) => (
  <Flex
    justifyContent="center"
    alignItems="center"
    bg="#fff"
    px={3}
    py={4}
    m={2}
    position="relative"
    width="100%"
  >
    <Icon icon={icon} height="140px" color={fill} />

    <Text
      position="relative"
      zIndex="2"
      textAlign="center"
      fontSize={4}
      as="h4"
px={3}
m={0}
      color={fill}
    >
      {description}
    </Text>
  </Flex>
)

export default ValueCard
