import React from 'react'
import styled from '@style'
import { Grid } from '@material-ui/core'
import { Box, Flex, Container } from '@components/Grid'
import { SectionTitle } from '@components/Text'
import BgImg from '@images/benefits.png'
import ImgSearch from '@images/job_Offer.png'
import SvgText from '../Program/SvgText'
import checkCircleFilled from '@iconify/icons-ant-design/check-circle-filled'
import likeIcon from '@iconify/icons-ei/like'

const Image = styled.img`
  overflow: hidden;
  height: 350px;
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    width: 100%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 100%;
  }
`
const StyledBox = styled(Box)`
  width: 100%;
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    width: 100%;
  }
`

const CareerOpportunities = () => (
  <Box as="section" py={6}>
    <StyledBox as="section" bg="white" py={2}>
      <Container>
        <Grid container justify="center">
          <Grid item xs={12}>
            <SectionTitle color="primary.900">¿Qué ofrecemos?</SectionTitle>
          </Grid>
          <Grid item container justify="center" xs={12} md={6}>
            <Image src={BgImg} />
          </Grid>
          <Grid item container alignItems="center" xs={12} md={6}>
            {/* <SvgText
              description="La continua transformación del mundo asegurador está haciendo que la profesionalidad y la especialización sea la mejor manera de mejorar resultados y reducir costes. Esta optimización de recursos nos llevará a ser más solventes y estar en continuo crecimiento."
              fill="hsl(242, 51%, 19%)"
              side
              icon={likeIcon}
            /> */}
            <SvgText
              description=" Formación continua desde el inicio y sesiones formativas todas
              las semanas para mantenerte actualizado."
              fill="hsl(242, 51%, 19%)"
              side
              icon={likeIcon}
            />
            <SvgText
              description="Desarrollo profesional con un plan de carrera individualizado."
              fill="hsl(242, 51%, 19%)"
              side
              icon={likeIcon}
            />
            <SvgText
              description="Sistema de comisiones atractivo, por encima del mercado."
              fill="hsl(242, 51%, 19%)"
              side
              icon={likeIcon}
            />
            <SvgText
              description="Contrato laboral."
              fill="hsl(242, 51%, 19%)"
              side
              icon={likeIcon}
            />
          </Grid>
        </Grid>
      </Container>
    </StyledBox>
    <StyledBox as="section" py={2}>
      <Container>
        <Grid container justify="center">
          <Grid item xs={12}>
            <SectionTitle color="white">Requisitos mínimos:</SectionTitle>
          </Grid>

          <Grid item container alignItems="center" xs={12} md={6}>
            <SvgText
              description="Experiencia previa en gestión patrimonial de clientes."
              color="white"
              fill="#fff"
              icon={checkCircleFilled}
            />
            <SvgText
              description="Se valorará contar con la certificación de asesor financiero."
              color="white"
              fill="#fff"
              icon={checkCircleFilled}
            />
            <SvgText description=" ¡Te esperamos!" color="white" fill="#fff" />
            <SvgText
              description="Si prefieres enviarnos tu CV, contáctanos a través del email"
              email="info@aibrok.com.mx"
              color="white"
              fill="#fff"
            />
          </Grid>
          <Grid item container justify="center" xs={12} md={6}>
            <Image src={ImgSearch} />
          </Grid>
        </Grid>
      </Container>
    </StyledBox>
  </Box>
)

export default CareerOpportunities
